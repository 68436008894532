document.addEventListener('DOMContentLoaded', function() {
    const countdownTimers = document.querySelectorAll('.wsc-countdown-timer-wrapper .wsc-countdown-timer');

    countdownTimers.forEach(countdownTimer => {
        let timeRemaining = parseInt(countdownTimer.getAttribute('data-wsc-time-remaining'));

        const updateCountdown = () => {
            const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

            countdownTimer.querySelector('.days .wsc-countdown-timer-value').textContent = days;
            countdownTimer.querySelector('.hours .wsc-countdown-timer-value').textContent = hours;
            countdownTimer.querySelector('.minutes .wsc-countdown-timer-value').textContent = minutes;
            countdownTimer.querySelector('.seconds .wsc-countdown-timer-value').textContent = seconds;

            // Decrement the remaining time
            timeRemaining -= 1000;

            if (timeRemaining < 0) {
                clearInterval(interval);
                countdownTimer.innerHTML = '<div class="wsc-countdown-timer-expired">EXPIRED</div>';
            }
        };

        // Update the countdown every second
        const interval = setInterval(updateCountdown, 1000);
    });
});